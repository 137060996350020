(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/text-expander/assets/javascripts/text-shortener.js') >= 0) return;  svs.modules.push('/components/lb-ui/text-expander/assets/javascripts/text-shortener.js');

'use strict';

const _excluded = ["children", "text", "maxLength", "isCollapsed"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], -1 === t.indexOf(o) && {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (-1 !== e.indexOf(n)) continue; t[n] = r[n]; } return t; }
const {
  lbUi
} = svs.components;
const TextShortener = _ref => {
  let {
      children,
      text,
      maxLength,
      isCollapsed
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const shortText = text.slice(0, maxLength);
  const isDotted = shortText.length !== text.length;
  return React.createElement("div", props, isDotted && isCollapsed ? React.createElement("span", null, lbUi.newLineToBreak(shortText), "...") : lbUi.newLineToBreak(text), typeof children === 'function' ? children(isDotted) : children);
};
TextShortener.defaultProps = {
  isCollapsed: true,
  text: ''
};
svs.components.lbUi.textExpander.TextShortener = TextShortener;

 })(window);